/* ADDS A BROWSER PREFIX TO THE PROPERTY */
@mixin css3-prefix($property, $value) {
	-webkit-#{$property}: #{$value};
	-khtml-#{$property}: #{$value};
	-moz-#{$property}: #{$value};
	-ms-#{$property}: #{$value};
	-o-#{$property}: #{$value};
	#{$property}: #{$value};
}

/* BACKGROUND GRADIENT */
@mixin background-gradient($startColor, $endColor, $orientation: vertical) {
	@if($orientation == 'vertical') {
		background-color: $startColor;
		background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
		background-image: -webkit-linear-gradient(top, $startColor, $endColor);
		background-image: -moz-linear-gradient(top, $startColor, $endColor);
		background-image: -ms-linear-gradient(top, $startColor, $endColor);
		background-image: -o-linear-gradient(top, $startColor, $endColor);
		background-image: linear-gradient(top, $startColor, $endColor);
	} @else {
		background-color: $startColor;
		background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
		background-image: -webkit-linear-gradient(left, $startColor, $endColor);
		background-image: -moz-linear-gradient(left, $startColor, $endColor);
		background-image: -ms-linear-gradient(left, $startColor, $endColor);
		background-image: -o-linear-gradient(left, $startColor, $endColor);
		background-image: linear-gradient(left, $startColor, $endColor);
	}
}

/* BACKGROUND SIZE */
@mixin background-size($width: 100%, $height: $width) {
	@if type-of($width) == 'number' and $height != null {
		@include css3-prefix('background-size', $width $height);
	} @else {
		@include css3-prefix('background-size', $width);
	}
}

/* BACKGROUND COLOR OPACITY */
@mixin background-opacity($color: #000, $opacity: 0.2) {
	background: $color;
	background: rgba($color, $opacity);
}

/* BORDER RADIUS */
@mixin border-radius($radius: 5px) {
	@include css3-prefix('border-radius', $radius);
}

/* BOX SHADOW */
@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.2), $inset: "") {
	@if ($inset != "") {
		@include css3-prefix('box-shadow', $inset $x $y $blur $color);
	} @else {
		@include css3-prefix('box-shadow', $x $y $blur $color);
	}
}

/* OPACITY */
@mixin opacity($opacity: 0.5) {
	$opacityMultiplied: ($opacity * 100);
	filter: alpha(opacity=$opacityMultiplied);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacityMultiplied + ")";
	@include css3-prefix('opacity', $opacity);
}

/* TRANSITION */
@mixin transition($what: all, $length: 0.2s, $easing: ease-out) {
	@include css3-prefix('transition', $what $length $easing);
}

/* PSEUDO CLASS STYLES */
@mixin pseudo() {
	content: "";
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;	
	pointer-events: none;
}

/* FONT FACE GENERATING */
@mixin font-face($style-name, $file) {
    $filepath: "../fonts/" + $file;
    @font-face {
        font: {
			family: "#{$style-name}";
			weight: normal;
			style: normal;
        }
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'), 
			 url($filepath + ".woff2") format('woff2'), 
			 url($filepath + ".woff") format('woff'), 
			 url($filepath + ".ttf") format('truetype'), 
			 url($filepath + ".svg#" + $style-name + "") format('svg');
    }
}

/* SHORTHAND IMG */
@mixin img($max-width: 100%) {
	width: 100%;
	max-width: $max-width;
	height: auto;	
}

/* RETINA IMAGES */
@mixin retina($img, $width, $height) {	
	@media (-webkit-min-device-pixel-ratio: 1.5),
	(min--moz-device-pixel-ratio: 1.5),
	(-o-min-device-pixel-ratio: 3/2),
	(min-device-pixel-ratio: 1.5),
	(min-resolution: 1.5dppx) {
		& {
			background-image: url($img);
			-webkit-background-size: $width $height;
			-moz-background-size: $width $height;
			background-size: $width $height;
		}
	}
}

/* KEYFRAME ANIMATION */
@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@-ms-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	}
}