/* CSS Document */
/* CSS Document */
/* ADDS A BROWSER PREFIX TO THE PROPERTY */
/* BACKGROUND GRADIENT */
/* BACKGROUND SIZE */
/* BACKGROUND COLOR OPACITY */
/* BORDER RADIUS */
/* BOX SHADOW */
/* OPACITY */
/* TRANSITION */
/* PSEUDO CLASS STYLES */
/* FONT FACE GENERATING */
/* SHORTHAND IMG */
/* RETINA IMAGES */
/* KEYFRAME ANIMATION */
html.remodal-is-locked {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}

/* Anti FOUC */
.remodal, [data-remodal-id] {
  display: none;
}

/* Necessary styles of the overlay */
.remodal-overlay {
  position: fixed;
  z-index: 9999;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  left: -5000px;
  display: none;
}

/* Necessary styles of the wrapper */
.remodal-wrapper {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0, 0, 0, 0.7);
}

.remodal-wrapper:after {
  display: inline-block;
  height: 100%;
  margin-left: -0.05em;
  content: "";
}

/* Fix iPad, iPhone glitches */
.remodal-overlay, .remodal-wrapper {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Necessary styles of the modal dialog */
.remodal {
  position: relative;
  outline: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-align: left;
}

.remodal-is-initialized {
  /* Disable Anti-FOUC */
  display: inline-block;
}

#cookiePolicy {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9999;
  font-size: 100%;
  color: #333;
}

#cookiePolicy a {
  text-decoration: underline;
  -webkit-transition: all 0.3s ease;
  -khtml-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#cookiePolicy a:hover {
  color: #8dc63f;
}

#cookiePolicy strong {
  font-weight: 600;
}

#cookiePolicy .row {
  display: table;
  padding: 8px 0 0 0;
  padding-left: 0.65em;
  padding-right: 0.65em;
}

#cookiePolicy .cookies-1 {
  padding-top: 6px;
  padding-bottom: 2px;
  padding-left: 0;
  padding-right: 0;
  font-size: 17px;
  line-height: 18px;
}

#cookiePolicy .cookies-2 {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0;
  padding-right: 0;
  font-size: 15px;
  line-height: 17px;
}

#cookiePolicy .cookies-3 a {
  position: relative;
  margin: auto 0;
  font-size: 14px;
  overflow: hidden;
  display: inline-block;
  padding: 2px 35px 2px 10px;
  color: #fff;
  text-decoration: none;
  background: linear-gradient(#8dc63f, #7fb239);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8dc63f', endColorstr='#7fb239',GradientType=0 );
  /* IE6-9 */
  /* border radius */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-clip: padding-box;
}

#cookiePolicy .cookies-3 a span {
  position: relative;
  display: inline-block;
  z-index: 999;
}

#cookiePolicy .cookies-3 a:after {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  content: " ";
  width: 30px;
  background: url("../img/cookie/tick.png") no-repeat right 7px center, linear-gradient(#719e32, #668e2e);
  -webkit-transition: all 0.3s ease;
  -khtml-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#cookiePolicy .cookies-3 a:hover:after {
  width: 100%;
}

#cookiePolicy:before {
  display: block;
  content: " ";
  position: absolute;
  top: 2px;
  right: 0;
  left: 0;
  height: 1px;
  background-color: rgba(200, 200, 200, 0.9);
}

#cookies-modal .cookies-popup {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1020px;
  margin: 80px auto 0;
  background: #319adb;
  background: linear-gradient(rgba(0, 0, 0, 0.1), transparent), #3da0dd;
  color: #fff;
}

#cookies-modal .cookies-popup strong {
  font-weight: 600;
}

#cookies-modal .cookies-popup .lightbox-inner {
  overflow: hidden;
}

#cookies-modal .cookies-popup .lightbox-inner .row {
  padding: 1.25em 1.5em 0 0.65em;
}

#cookies-modal .cookies-popup .lightbox-inner .column.slvzr-last-child {
  padding-right: 1.5em;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-header {
  background: #329adb;
  position: relative;
  padding: 10px 0 10px 80px;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-header .large {
  font-size: 25px;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-header:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  content: " ";
  display: block;
  width: 54px;
  height: 53px;
  background-image: url(../img/cookie/cog.png);
  background-position: left center;
  background-repeat: no-repeat;
  margin: auto;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-header:after {
  content: '';
  display: block;
  width: 100%;
  height: 26px;
  pointer-events: none;
  position: absolute;
  bottom: -26px;
  left: 0;
  background: url(../img/cookie/shadow.png) center top no-repeat;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-header .column {
  line-height: 20px;
  margin-bottom: 20px;
  padding-right: 10px;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-header .acceptCookie {
  position: relative;
  margin: auto 0;
  font-size: 15px;
  overflow: hidden;
  display: inline-block;
  padding: 12px 70px 12px 30px;
  color: #fff;
  text-decoration: none;
  background: linear-gradient(#8dc63f, #7fb239);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8dc63f', endColorstr='#7fb239',GradientType=0 );
  /* IE6-9 */
  /* border radius */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-clip: padding-box;
  box-shadow: 0 2px #2674a4;
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -khtml-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-header .acceptCookie span {
  position: relative;
  display: inline-block;
  z-index: 999;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-header .acceptCookie:after {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  content: " ";
  width: 40px;
  background: url("../img/cookie/l-tick.png") no-repeat right 10px center, linear-gradient(#719e32, #668e2e);
  -webkit-transition: all 0.3s ease;
  -khtml-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-header .acceptCookie:hover {
  box-shadow: 0 0px #2674a4;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-header .acceptCookie:hover:after {
  width: 100%;
}

#cookies-modal .cookies-popup .lightbox-inner .section {
  overflow: hidden;
}

#cookies-modal .cookies-popup .lightbox-inner .section h2 {
  position: relative;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#cookies-modal .cookies-popup .lightbox-inner .section h2:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 103%;
  content: " ";
  display: block;
  height: 1px;
  width: 1000%;
  margin: auto 0;
  background-color: #fff;
}

#cookies-modal .cookies-popup .lightbox-inner .section p {
  font-size: 15px;
  margin-bottom: 15px;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-table ul li {
  clear: none !important;
  font-size: 15px;
  padding: 10px 30px;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-table ul li.dark {
  background: #3482b3;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-table ul li.header {
  font-weight: 600;
  font-size: 15px;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-content {
  overflow: hidden;
  padding: 15px 40px;
}

#cookies-modal .cookies-popup .lightbox-inner .cookie-content a {
  text-decoration: underline;
  color: #A8DEFF;
}

#cookies-modal .cookies-popup .close-image-lightbox {
  position: absolute;
  top: -50px;
  right: 0;
  display: inline-block;
  height: 35px;
  width: auto;
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;
  padding-right: 45px;
}

#cookies-modal .cookies-popup .close-image-lightbox .icon {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff;
}

#cookies-modal .cookies-popup .close-image-lightbox .icon:before, #cookies-modal .cookies-popup .close-image-lightbox .icon:after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 2px;
  margin: -1px 0 0 -8px;
  background: #000;
  -webkit-transition: all 0.3s linear;
  -khtml-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

#cookies-modal .cookies-popup .close-image-lightbox .icon:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#cookies-modal .cookies-popup .close-image-lightbox .icon:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#cookies-modal .cookies-popup .close-image-lightbox:hover .icon:before {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

#cookies-modal .cookies-popup .close-image-lightbox:hover .icon:after {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

@media only screen and (max-width: 25.063em) {
  #cookiePolicy .column, #cookiePolicy .columns {
    text-align: center;
  }
  #cookiePolicy .cookies-3 a {
    width: 100%;
    max-width: 90%;
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 5px 0 5px 0;
  }
  #cookiePolicy .cookies-3 a span {
    font-size: 16px;
  }
}

@media only screen and (max-width: 40em) {
  #cookies-modal .cookies-popup .lightbox-inner .cookie-content {
    padding: 15px 0px;
  }
  #cookies-modal .cookies-popup .lightbox-inner .cookie-table ul li {
    width: 50%;
  }
}
