@charset "UTF-8";
/* CSS Document */

@charset "UTF-8";
/* CSS Document */

@import "bourbon/bourbon";
@import "components/_mixins";

html.remodal-is-locked {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}

/* Anti FOUC */

.remodal, [data-remodal-id] {
  display: none;
}

/* Necessary styles of the overlay */

.remodal-overlay {
  position: fixed;
  z-index: 9999;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  left: -5000px;
  display: none;
}

/* Necessary styles of the wrapper */

.remodal-wrapper {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0,0,0,0.7);
  &:after {
    display: inline-block;
    height: 100%;
    margin-left: -0.05em;
    content: "";
  }
}

/* Fix iPad, iPhone glitches */

.remodal-overlay, .remodal-wrapper {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Necessary styles of the modal dialog */

.remodal {
  position: relative;
  outline: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-align: left;
}

.remodal-is-initialized {
  /* Disable Anti-FOUC */
  display: inline-block;
}

#cookiePolicy {
	@include position(fixed, null  0 0 0);
	//height: 40px;
	background: #fff;
	background: rgba(255,255,255,0.9);
	z-index: 9999;
	font-size: 100%;
	color: #333;

	a {
		text-decoration: underline;
		@include transition(all, 0.3s, ease);
		&:hover {
			color: #8dc63f;
		}
	}

	strong {
		font-weight: 600;
	}

	.row {
		display: table;
		padding: 8px 0 0 0;
		padding-left: 0.65em;
		padding-right: 0.65em;
	}

	.column, .columns {
		//padding-bottom: 8px;
	}

	//.row, .column, .columns {
	//	height: inherit;
	//}

	.cookies-1 {
		padding-top: 6px;
		padding-bottom: 2px;
		padding-left: 0;
		padding-right: 0;
		font-size: 17px;
		line-height: 18px;
	}

	.cookies-2 {
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 0;
		padding-right: 0;
		font-size: 15px;
		line-height: 17px;
	}

	.cookies-3 {
		a {
			@include position(relative, null null null null);
			margin: auto 0;
			font-size: 14px;

			overflow: hidden;
			display: inline-block;
		    padding: 2px 35px 2px 10px;
			color: #fff;
			text-decoration: none;

			background: linear-gradient(#8dc63f, #7fb239);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8dc63f', endColorstr='#7fb239',GradientType=0 ); /* IE6-9 */

			/* border radius */
			@include border-top-radius(5px);
			@include border-bottom-radius(5px);

			background-clip: padding-box;

			span {
				position: relative;
				display: inline-block;
				z-index: 999;
			}

			&:after {
				display: inline-block;
				@include position(absolute, 0 0 0 null);
				content: " ";
				width: 30px;
				background: url('../img/cookie/tick.png') no-repeat right 7px center, linear-gradient(shade(#8dc63f, 20%), shade(#7fb239, 20%));
				@include transition(all, 0.3s, ease);
			}

			&:hover {

				&:after {
					width: 100%;
				}
			}
		}
	}

	&:before {
		display: block;
		content: " ";
		@include position(absolute, 2px 0 null 0);
		height: 1px;
		background-color: rgba(200,200,200,0.9);

	}
}

#cookies-modal {
	.cookies-popup {
		position: relative;
	    z-index: 2;
	    width: 100%;
	    max-width: 1020px;
	    margin: 80px auto 0;
	   	background: #319adb;
	    background: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0)), #3da0dd;
	    color: #fff;
	    strong {
	    	font-weight: 600;
	    }
	    .lightbox-inner {
	    	overflow: hidden;
	    	.row {
		    	padding: 1.25em 1.5em 0 0.65em;
	    	}
	    	.column.slvzr-last-child {
	    		padding-right: 1.5em;
	    	}
	    	.cookie-header {
	    		background: #329adb;
	    		position: relative;
	    		padding: 10px 0 10px 80px;
	    		.large {
	    			font-size: 25px;
	    		}
	    		&:before {
	    			@include position(absolute, 0 null 0 20px);
	    			content: " ";
	    			display: block;
	    			width: 54px;
	    			height: 53px;
	    			background-image: url(../img/cookie/cog.png);
		    		background-position: left center;
		    		background-repeat: no-repeat;
		    		margin: auto;
	    		}
	    		&:after {
	    			content: '';
				    display: block;
				    width: 100%;
				    height: 26px;
				    pointer-events: none;
				    position: absolute;
				    bottom: -26px;
				    left: 0;
				    background: url(../img/cookie/shadow.png) center top no-repeat;
	    		}
	    		.column {
				    line-height: 20px;
				    margin-bottom: 20px;
				    padding-right: 10px;
	    		}
	    		.acceptCookie {
	    			@include position(relative, null null null null);
					margin: auto 0;
					font-size: 15px;
					overflow: hidden;
					display: inline-block;
				    padding: 12px 70px 12px 30px;
					color: #fff;
					text-decoration: none;
					background: linear-gradient(#8dc63f, #7fb239);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8dc63f', endColorstr='#7fb239',GradientType=0 ); /* IE6-9 */
					/* border radius */
					@include border-top-radius(5px);
					@include border-bottom-radius(5px);
					background-clip: padding-box;
					box-shadow: 0 2px #2674a4;
					width: 100%;
					@include transition(all, 0.3s, ease);

					span {
						position: relative;
						display: inline-block;
						z-index: 999;
					}

					&:after {
						display: inline-block;
						@include position(absolute, 0 0 0 null);
						content: " ";
						width: 40px;
						background: url('../img/cookie/l-tick.png') no-repeat right 10px center, linear-gradient(shade(#8dc63f, 20%), shade(#7fb239, 20%));
						@include transition(all, 0.3s, ease);
					}

					&:hover {
						box-shadow: 0 0px #2674a4;
						&:after {
							width: 100%;
						}
					}
	    		}
	    	}

	    	.section {
	    		overflow: hidden;
	    		h2 {
	    			position: relative;
	    			color: #fff;
	    			display: inline-block;
	    			font-size: 18px;
	    			margin-top: 20px;
	    			margin-bottom: 20px;

	    			&:after {
	    				@include position(absolute, 0 0 0 103%);
	    				content: " ";
	    				display: block;
	    				height: 1px;
	    				width: 1000%;
	    				margin: auto 0;
	    				background-color: #fff;
	    			}
	    		}

	    		p {
	    			font-size: 15px;
	    			margin-bottom: 15px;
	    		}
	    	}

	    	.cookie-table {
	    		ul {
	    			li {
	    				clear: none !important;
	    				font-size: 15px;
    				    padding: 10px 30px;

	    				&.dark {
	    					background: #3482b3;
	    				}

	    				&.header {
	    					font-weight: 600;
	    					font-size: 15px;
	    				}
	    			}
	    		}
	    	}

	    	.cookie-content {
	    		overflow: hidden;
	    		padding: 15px 40px;
	    		a {
	    			text-decoration: underline;
	    			color: #A8DEFF;
	    		}
	    	}
	    }



		.close-image-lightbox {
		    position: absolute;
		    top: -50px;
		    right: 0;
		    display: inline-block;
		    height: 35px;
		    width: auto;
		    text-transform: uppercase;
		    color: #fff;
		    font-size: 20px;
		    font-weight: 600;
		    //font-family: 'pfd medium';
		    line-height: 35px;
		    padding-right: 45px;
		    .icon {
				position: absolute;
				top: 0;
				right: 0;
				display: block;
				width: 35px;
				height: 35px;
				border-radius: 50%;
				background: #fff;
			    &:before, &:after {
			        content: " ";
			        position: absolute;
			        top: 50%;
			        left: 50%;
			        width: 16px;
			        height: 2px;
			        margin: -1px 0 0 -8px;
			        background: #000;
			        @include transition(all, 0.3s, linear);
			    }
			    &:before {
			        -webkit-transform: rotate(-45deg);
			        -ms-transform: rotate(-45deg);
			        transform: rotate(-45deg);
			    }
			    &:after {
			        -webkit-transform: rotate(45deg);
			        -ms-transform: rotate(45deg);
			        transform: rotate(45deg);
			    }
			}
		    &:hover {
		    	.icon:before {
			        -webkit-transform: rotate(0deg);
			        -ms-transform: rotate(0deg);
			        transform: rotate(0deg);
			    }
			    .icon:after {
			        -webkit-transform: rotate(0deg);
			        -ms-transform: rotate(0deg);
			        transform: rotate(0deg);
			    }
		    }
		}
	}

}

@media only screen and (max-width: 641px) {
	#cookiePolicy {
		
	}
}

@media only screen and (max-width: 25.063em) {
	#cookiePolicy {
		//.cookies-1 {
		//	font-size: 5px;
		//}

		//.cookies-2 {
		//	font-size: 13px;
		//	line-height: 14px;
		//}

		.column, .columns {
			text-align: center;
		}

		.cookies-3 {
			a {
				width: 100%;
				max-width: 90%;
			    padding-top: 7px;
			    padding-bottom: 7px;
			    margin: 5px 0 5px 0;
				span {
					font-size: 16px;
				}
				&:after {
				}
			}
		}
	}
}

@media only screen and (max-width: 40em) {
	#cookiePolicy {
		//.cookies-1 {
		//	font-size: 15px;
		//}

		//.cookies-2 {
		//	font-size: 14px;
		//	line-height: 16px;
		//}
	}

	#cookies-modal {
		.cookies-popup {
			.lightbox-inner {
				.cookie-content {
					padding: 15px 0px;
				}
			}
		}
	}

	#cookies-modal .cookies-popup .lightbox-inner .cookie-table ul li {
		width: 50%;
	}
}
